//ADD FONT WITH JS // CANNOT OVERRIDE MAIN.PHP MOBILE
$(function () {
    $('head').append('<link rel="stylesheet" href="https://use.typekit.net/sue3lqo.css" />');

    if($('.bandeauMenuAffix').length) {
        $('body').addClass('hasBando');
    }

    /**
     * Account dropdown from menu
     */
    if ($('#left_menu .bloc_lnk.user.connected.dropdown').length > 0) {

        $(document).on('click tap touch', '.bloc_lnk.user.connected.dropdown', function() {

            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).parent().find('.dropdown.mon-compte').slideUp(300);
            } else {
                $(this).addClass('active');
                $(this).parent().find('.dropdown.mon-compte').slideDown(300);
            }
        });
    }

    /* Changer le positionnement du bloc product_attributs */
    $(function() {
        if ($('body.step_1 .pushBasketWrapper').length && $('body.step_1 .cart_main_table.wishlist').length) {
            $('body.step_1 .pushBasketWrapper').insertBefore('body.step_1 .cart_main_table.wishlist').show();
        } else {
            $('body.step_1 .pushBasketWrapper').insertBefore('body.step_1 .bloc_question').show();
        }

        if ($('body.step_1 .pushBasketWrapperText').length) {
            $('body.step_1 .pushBasketWrapperText').insertBefore('body.step_1 .tunnel_step_title.cart_title').show();
        }
    });

    // FOOTER ACCORDION
    $('.footer_accordion .col_title').each(function () {
        $(this).click(function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).parent().find('.col_content').removeClass('active').slideUp(300);
            }
            else {
                $('.footer_accordion .col_title').removeClass('active');
                $('.footer_accordion .col_content').removeClass('active').slideUp(300);
                $(this).addClass('active');
                $(this).parent().find('.col_content').addClass('active').slideDown(300);
            }
        });
    });

    // Page message txt
    var messageHistorique = Translator.translate('message_historique');
    $("<span class='message_historique'>"+messageHistorique+"</span>").prependTo("body.customer #contents_wrapper .mes_messages");

    var messageNonLu = Translator.translate('message_non_lu');
    var messageLu = Translator.translate('message_lu');
    $("body.customer #contents_wrapper .mes_messages .wrapper_detail_message").each(function(){
        if($(this).hasClass("unread")){
            $(this).find("span.enveloppe").text(messageNonLu);
        }
        else{
            $(this).find("span.enveloppe").text(messageLu);
        }
    });

    setTimeout(function () {
        if ($("#sliderBando .swiper-slide").length > 1) {
            var sliderBando = new Swiper('#sliderBando', {
                centeredSlides: true,
                loop: true,
                slidesPerView: 'auto',
                speed: 1500,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: false,
                },
                autoplayDisableOnInteraction: false
            });
        }
    }, 250);

    if ($('.wishlist .wishlist_container .form_submit span').length) {

        $('.wishlist .wishlist_container .form_submit span').text(Translator.translate('share_wishlist_mobile'));
    }

    //HP SLIDERS
    if($(".cover-module .swiper-slide").length > 1) {
        var coverSwiper = new Swiper(".cover-module .swiper-container", {
            paginationClickable: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            autoplay: {
                delay: 3000,
            },
            speed: 1500
        });
    }

    if ($(".tg-module-1 .swiperTg .swiper-slide").length > 1) {
        instaSwiper = new Swiper(".tg-module-1 .swiperTg", {
            slidesPerView: 1.5,
            centeredSlides: true,
            spaceBetween: 4,
            initialSlide: 1,

            navigation: {
                nextEl: ".tg-module .swiper-button-next",
                prevEl: ".tg-module .swiper-button-prev",
            },
        });
    }

    if ($(".social-1 .swiper_insta").length) {
        instaSwiper = new Swiper(".social-1 .swiper_insta", {
            slidesPerView: 3,
            spaceBetween: 20,
            loop: true,
            allowTouchMove: false,
            autoplay: {
                delay: 0,
            },
            speed: 2500
        });
    }

    if ($(".social-1 .swiper_insta_posts").length) {
        instaSwiper = new Swiper(".social-1 .swiper_insta_posts", {
            slidesPerView: 3,
            spaceBetween: 20,
            loop: true,
            allowTouchMove: false,
            autoplay: {
                delay: 0,
            },
            speed: 2500
        });
    }

    // Home category menu swiper
    if ($('.pushCategSlider').length) {
        var pushCategSlider = new Swiper('.pushCategSlider.swiper-container ', {
            slidesPerView: 'auto',
        });
    }

    //CATEGORY SLIDER
    if  ($(".category #sliderMenuRayon .swiper-slide").length > 2) {
        categSwiper = new Swiper(".category #sliderMenuRayon", {
            slidesPerView: 2.4,
            spaceBetween: 10
        });
    }

    //FP SLIDERS
    if ($(".product_page #reassu_fp").length) {
        instaSwiper = new Swiper("#reassu_fp.reassurance_swiper", {
            slidesPerView: 3,
            spaceBetween: 25,
            loop: true,
            allowTouchMove: false,
            autoplay: {
                delay: 0,
            },
            speed: 2500
        });
    }

    /**
     * Toggle txt SEO - CATEGORY PAGE - FOOTER
     */
     if ($('.category .bloc_seo .see_more_txt').length) {

        $('.bloc_seo .see_more_txt').on('click tap touch', function() {
            var heightToToggle = $(this).siblings('.txt_content').height();

            if ($(this).parent('.bloc_seo').hasClass('actif')) {

                $(this).parent('.bloc_seo').removeClass('actif');
                $(this).parent('.bloc_seo').animate({height: "252px"}, 500);
                $(this).children('.see_more_txt_cta').text(Translator.translate('txt_show_more'));

            } else {

                $(this).parent('.bloc_seo').addClass('actif');
                $(this).parent('.bloc_seo').animate({height: heightToToggle + 75}, 500);
                $(this).children('.see_more_txt_cta').text(Translator.translate('txt_show_less'));
            }
        });
    }

    $(document).on('scroll', function(){
        if ($(document).scrollTop() === 0 && $('.category #site_head.hasVisuOnCateg').length) {
            $('.category #site_head.hasVisuOnCateg').removeClass('scrolled');
        } else {
            $('.category #site_head.hasVisuOnCateg').addClass('scrolled');
        }

        if ($(document).scrollTop() === 0 && $('.category').length) {
            $('.category .banner_nav').addClass('upper_categ');
        } else {
            $('.category .banner_nav').removeClass('upper_categ');
        }
    });

    // Chargement de la tarification
    if ($("#tarification_load").length > 0) {
        // Chargement des différentes tarification
        $.ajax({
            url: path_relative_root + create_link("ajax_tarification_list"),
            type: 'post',
            dataType: 'json',
            success: function (res) {
                if (res !== []) {
                    var div = $('<div>', {'class': 'col_title custom-select custom-select-footer'});
                    var select = $('<select id="country_tarif" name="country">');
                    $.each(res, function (key, val) {
                        var option = $("<option />", {value: key, text: val.pays_nom});
                        if (val.selected) {
                            option.prop('selected', true)
                        }
                        $(option).appendTo(select);
                    });
                    // Event sur le select lorsque l'on change de pays
                    select.on('change', function () {
                        var country_iso = $(this).val();
                        // On va envoyé au serveur que l'on change de pays
                        $.ajax({
                            url: path_relative_root + create_link("ajax_tarification_change_country"),
                            type: 'post',
                            data: 'iso=' + country_iso,
                            success: function () {
                                location.reload();
                            }
                        });
                    });
                    select.appendTo(div);
                    div.appendTo($("#tarification_load"));
                } else {
                    $("#tarification_load").css('display', 'none');
                }
            }
        });
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
                $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
                $('.misc_cookiesinfo #shadCookie').removeClass('actif');
            }, 500);
        }
    });
});

function toggleMenuExtra() {
    // Check if search is openned and we are trying to close the menu
    if ($('body').hasClass('search_opened') && !$('#left_menu').hasClass('open')) {
        // Ensures the shad remains openned since search is still active
        shade.addClass('visible');
    }
}

function searchToggle() {

    var top_search = $('#top_search'),
        main_menu_btn = $('#main_menu_btn'),
        search_home = $('#search_home'),
        shade = $('#shade'),
        body = $('body');

    if(top_search.hasClass('opened')) {

        if(main_menu_btn.hasClass('menu_open')) {

            main_menu_btn.click();
            search_home.focus();
        } else {
            search_home.val('');
            top_search.removeClass('opened');
            body.removeClass('search_opened');
            shade.removeClass('visible');
        }
    } else {

        if(main_menu_btn.hasClass('menu_open')) {

            main_menu_btn.click();
            top_search.addClass('opened');
            body.addClass('search_opened');
            search_home.focus();
            shade.addClass('visible');

            shade.on('click', function () {

                if(!search_home.val()) {

                    searchToggle();
                }
            });
        } else {

            top_search.addClass('opened');
            body.addClass('search_opened');
            search_home.focus();
            shade.addClass('visible');

            shade.on('click', function () {

                if(!search_home.val()) {
                    searchToggle();
                }
            });
        }
    }
}

/**
* @ Swiper mobile "Mon compte" menu
*/

$(function () {
    setTimeout(function() {
        if ((
            $('body').hasClass('customer') ||
            $('body').hasClass('wishlist') ||
            $('body').hasClass('parrainage_index') ||
            $('body').hasClass('newsletter') ||
            $('body').hasClass('order')
            ) && $('#menu_account_slider_mob').length > 0) {

            setTimeout(function() {
                var menuInitialSlide = 0;

                $('.menu_account_slider_mob').css({ 'display': 'block', 'opacity': '1' });

                $('.menu_account_slider_mob .swiper-slide').each(function (i, obj) {
                    if ($(obj).hasClass('activeMenu')) {
                        menuInitialSlide = i;
                    }
                });

                var menuAccSwiperMob = new Swiper(".menu_account_slider_mob", {
                    slidesPerView: 'auto',
                    spaceBetween: 35,
                    slidesOffsetBefore: 0,
                    centeredSlides: true,
                    initialSlide: menuInitialSlide,
                    navigation: {
                        nextButton: '.swiper-button-nexts',
                        prevButton: '.swiper-button-prevs',
                    },
                });

            }, 500);
        }
    }, 500);

    // Page order
    var dateTxt = Translator.translate('date_txt_mob');
    var statusTxt = Translator.translate('status_txt_mob');
    $("body.order .recap_shipping .user_address:first-of-type .address_info span:first-child").prepend(dateTxt);
    $("body.order .recap_shipping .user_address:first-of-type .address_info span + span:not(.num_suivi_colis)").prepend(statusTxt);

    var montantTotal = Translator.translate('montant_total_mob');
    var deLaCommande = Translator.translate('de_la_commade_mob');
    $("body.order .wrapper_bottom_cmd .order_recap").prepend("<div><span class='montant_total'>"+montantTotal+"</span><span class='total_commande'>"+deLaCommande+"</span></div>");

});

var geo_txt_mob = Translator.translate('geo_txt_mob');

$('.store_locator #home .wrapper_produits .section-link-bottom .shop_map_elements a.pink_btn').each(function() {
    $(this).empty();
    $(this).append(geo_txt_mob);
});

setTimeout(function() {
    $("body.store_locator #wrapper_content_search_shop #search_form #home .section-link-bottom").prop("onclick", null).off("click");

    $('#send_store_information').on('click', function () {
        $("#shade.modal_shade").css("z-index","31");
    });

}, 1500);

function faqinit(array_questions) {

    $(document).on('change keyup focus', '#faq_search', function () {

        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {

            $('.search_results li').show().removeClass("hasMatch");
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function (i, val) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
            }

        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    var click_in_process = false;

    $('.search_results').mousedown(function () {
        click_in_process = true;
    });

    $('.search_results').mouseup(function () {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#faq_search').blur(function () {
        if (!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    $('#theme_global .theme').on('click', function () {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        var site_search = $('body.faq .wrapper_moncompte .site_search');
        var theme_selector = $('#theme_global .theme');
        var faq_bloc = $('#theme_page .theme_detail .faq_element');
        $('.title_detail_sub').css('display', 'none');
        site_search.toggleClass('hide');
        theme.addClass('actif');
        showTabFaq(id, 1);

        theme_selector.removeClass('active');
        $(this).addClass('active');

        $("html, body").animate({scrollTop: faq_bloc.offset().top - 200,},500);
    });
}

function researchFaq(node_id, level, question_id) {

    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate({ scrollTop: $(".faq_question_" + question_id + "").offset().top - 100 }, 1000);

}

function addFileInput2(id_input,string){

    var field = (typeof(string) != 'undefined') ? string:'contactProduitPhoto';

    id = parseInt(id_input);
    var next_file = id + 1;
    var file = $('#'+field+id_input)[0].files[0];
    var error = false;
    var input = field == 'contactProduitPhoto' ? $('#photoProduit_display') : $('#photoProduitEndommage_display');
    var nb_input = field == 'contactProduitPhoto' ? $(input).val() : $(input).val()  ;
    var  reset_file = document.getElementById('reset_file');
    nb_input = parseInt(nb_input);
    var size = typeof file != "undefined" ?  file.size : 0;
    var type = typeof file != "undefined" ? file.type : '';
    var allowedType = new Array('image/jpeg', 'image/png', 'application/pdf'); // Type de fichiers autoris�s
    if (size>4000000){
        alert('Poids Max : 4Mo');
        error =  true;
    }else if (jQuery.inArray(type, allowedType) == -1){
        alert('Format autorisé: jpg, png, pdf');
        error =  true;
    }
    if (error) {
        if( id_input == 1 ){
            $('#field_send').addClass('cache');
            $('#'+field+next_file).remove();
        }
        $('#'+field+id_input).val('');
    } else {

        if( id_input == 1 ){
            $('#field_send').removeClass('cache');
            if(field == 'filepj') {
                $('.msgBox .file_msg p').text($('#filepj1')[0].files[0].name);
                reset_file.style.display = "block";
            }
            if(field == 'contactProduitPhoto') {
                $('.msgBox .file_msg p').text($('#contactProduitPhoto1')[0].files[0].name);
                reset_file.style.display = "block";
            }

            if(field == 'contactProduitPhotoEndommage') {
                $('.msgBox .file_msg p').text($('#contactProduitPhotoEndommage1')[0].files[0].name);
                reset_file.style.display = "block";
            }
        }
        if (field != "filepj") {
            $(input).attr("value", next_file);
        }

    }
}

function resetFile(id_input) {

    if (id_input == "filepj1") {
        var  file = document.getElementById('filepj1');
        var  reset_file = document.getElementById('reset_file');
        reset_file.style.display = "none";
        $('.msgBox .file_msg p').text('');
        file.value = '';
    }
    if (id_input == "imagepj1") {
        var  image = document.getElementById('imagepj1');
        var  reset_image = document.getElementById('reset_image');
        reset_image.style.display = "none";
        $('.msgBox .image_msg p').text('');
        image.value = '';
    }

    if(id_input == 'contactProduitPhotoEndommage') {
        var  file2 = document.getElementById('contactProduitPhotoEndommage1');
        var  reset_file2 = document.getElementById('reset_file');
        reset_file2.style.display = "none";
        $('.msgBox .file_msg p').text('');
        file2.value = '';
    }

    if(id_input == 'contactProduitImageEndommage') {
        var  image2 = document.getElementById('contactProduitImageEndommage1');
        var  reset_image2 = document.getElementById('reset_image');
        reset_image2.style.display = "none";
        $('.msgBox .image_msg p').text('');
        image2.value = '';
    }

}

function openColorAchatExpressV2() {
    var $div_color = $(this.form).find('.productColorFieldset');
    var $div_size = $(this.form).find('.productSizeFieldset');
    var div_color_actif = $div_color.hasClass('actif');

    var lightbox = $(this.form).find('.achat_express');
    var lightBoxAlertStock = lightbox.hasClass('displayInfoStock');

    var $div_alert_stock = $(this.form).find('.bloc_add_alert');
    $div_alert_stock.hide();

    $('.achat_express').hide();
    $('.productColorFieldset.actif').removeClass('actif');
    $('.productSizeFieldset.actif').removeClass('actif');

    if (lightBoxAlertStock) {
        lightbox.addClass('displayColors');
    }
    if (div_color_actif) {
        $(this.form).find('.achat_express').hide();
        $div_color.removeClass('actif');

    } else {
        $(this.form).find('.achat_express').show();
        $div_color.addClass('actif');
        $div_size.removeClass('actif');
    }

    if (!$('body').hasClass('homepage')) {
        $("#shade").addClass("isExpress").addClass("visible");
    }

    //Hide shade when color change
    $('.rollover.achat_express .productColorFieldset .color label').on('click tap touch', function(){
        if ($('body').hasClass('category') || $('body').hasClass('search')) {
            $("#shade").removeClass("isExpress").removeClass("visible");
        }

    });
}

/**
 * Clear search input or close search form
 */
 function clearSearch() {

    var search_home = $('#search_home');

    if (!search_home.val()) {

        showhide_search();
    } else {

        search_home.val('');
        search_home.focus();
    }
}

/**@ page order details when click demande retour */
function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    var numDIVObjet = document.getElementById("popup_numero_retour");

    numDIVObjet.style.display = "block";
    document.getElementById("general_shade").style.display = "block";
    document.getElementById("general_shade").style.opacity = "1";

    var scrollTop = $(window).scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);


    $("html, body").animate({ scrollTop: 0 }, (speed * 2), function () { });
}

/* MODULE FP */

setTimeout(function(){
    if ($(".edito_swiper .swiper-slide").length > 2) {
        edito_swiper = new Swiper(".edito_swiper", {
            slidesPerView: 1.5,
            spaceBetween: 5,
            centeredSlides: true,
            navigation: {
                nextEl: '.edito_swiper_next',
                prevEl: '.edito_swiper_prev',
            },
         });
    }
},750);

$(function() {
    setTimeout(function(){
            $('.content_product .ligne_produit_offert').each(function() {
                var thisprodColor = $(this).find(".third_bloc_produit_offert .prodColor");
                var thisprodwrapper = $(this).find(".wrapper_info_prod .wrapper_other");

                thisprodColor.appendTo(thisprodwrapper);
            });

            $("#selectionOfferteContainer .ligne_produit_offert").each(function() {
                var txt_valider_cp = Translator.translate('ajouter_au_panier');

                $(this).on('click', function () {
                     $("#selectionOfferteContainer .ligne_produit_offert .btn_choose_product span").replaceWith('<span>' + txt_valider_cp + '</span>');
                });
            });

    },1000);
});

function openMultiShad(id) {

    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {

        $("#" + id).slideDown(600);
    });

    $("#general_shade").css("display", "block").fadeTo("slow", 0.8).on("click", function () {

        closeMultiShad(id);
    });
}

function closeMultiShad(id) {

    $('#general_shade').off('click');

    // Hide modbox and shade and destroy shade's click event
    $('#' + id).slideUp("slow").queue(function () {

        if ($('#general_shade').is(":visible")) {
            $('#general_shade').fadeTo("slow", 0, function () {

                $('#general_shade').css('display', 'none');
            });
        }

        $(this).dequeue();
    });
}

$(function () {
    var cgvText = $('#exit_overlay_OVI .w-adwords-form .pop_cgv_listing');
    var btnSubmit = $('#exit_overlay_OVI .w-adwords-form .w-form-line-container .w-submit');

    if( cgvText.length && btnSubmit.length) {
        cgvText.insertBefore(btnSubmit);
    }
});

$.fn.boom = function(e) {
    var colors = [
        '#FF7870',
        '#FF9999',
        '#FFCCCC',
    ];
    var shapes = [
        '<path class="heartFull" d="M9.72,18a.56.56,0,0,1-.39-.17L1.85,10.35a6.13,6.13,0,0,1-.27-8.5,6,6,0,0,1,8.14-.47,6,6,0,0,1,8.14.47,6.14,6.14,0,0,1-.29,8.5l-7.46,7.52a.59.59,0,0,1-.36.13ZM2.37,2.58a5,5,0,0,0,.27,7l7.08,7.12L16.8,9.57a5,5,0,0,0,.27-7,4.9,4.9,0,0,0-7-.06.54.54,0,0,1-.78,0A4.84,4.84,0,0,0,5.89,1.1H5.83A4.77,4.77,0,0,0,2.37,2.58ZM15.75,5.43A2.41,2.41,0,0,0,14,3.7a.55.55,0,0,1-.4-.67.55.55,0,0,1,.68-.4,3.5,3.5,0,0,1,2.52,2.52.55.55,0,0,1-.4.68l-.14,0h0a.54.54,0,0,1-.53-.42Z"/>',
        '<path class="heartOutline" d="M12,22.23h0a.67.67,0,0,1-.48-.2L2.29,12.79A7.51,7.51,0,0,1,0,7.61,7.59,7.59,0,0,1,2,2.29,7.37,7.37,0,0,1,12,1.7,7.41,7.41,0,0,1,16.72,0a7.3,7.3,0,0,1,5.34,2.29,7.57,7.57,0,0,1-.36,10.5l-9.21,9.29A.74.74,0,0,1,12,22.23Zm5.45-19a.72.72,0,0,0-.67.52.69.69,0,0,0,.5.83,3,3,0,0,1,2.13,2.14.68.68,0,0,0,.66.51l.18,0a.7.7,0,0,0,.49-.84,4.36,4.36,0,0,0-3.12-3.12l-.17,0Z"/>',
    ];

    var btn = $(this);
    var group = [];
    var num = 20;

    for(i = 0; i < num; i++) {
        var randBG = Math.floor(Math.random() * colors.length);
        var getShape = Math.floor(Math.random() * shapes.length);
        var c = Math.floor(Math.random() * 10) + 5;
        var scale = Math.floor(Math.random() * (8 - 4 + 1)) + 4;
        var x = Math.floor(Math.random() * (150 + 100)) - 100;
        var y = Math.floor(Math.random() * (150 + 100)) - 100;
        var sec = Math.floor(Math.random() * 1700) + 1000;
        var cir = $('<div class="cir"></div>');
        var shape = $('<svg class="shape">'+shapes[getShape]+'</svg>');

        shape.css({
            top: e.pageY - btn.offset().top + 20,
            left: e.pageX - btn.offset().left + 40,
            'transform': 'scale(1.'+scale+')',
            'transition': sec + 'ms',
            'fill': colors[randBG]
        });

        btn.siblings('.btn-particles').append(shape);

        group.push({shape: shape, x: x, y: y});
    }

    for (var a = 0; a < group.length; a++) {
        var shape = group[a].shape;
        var x = group[a].x, y = group[a].y;
        shape.css({
            left: x + 50,
            top: y + 15,
            'transform': 'scale(0)'
        });
    }

    setTimeout(function() {
        for (var b = 0; b < group.length; b++) {
            var shape = group[b].shape;
            shape.remove();
        }
        group = [];
    }, 2500);

}

$(function() {
    $(document).on('click tap touch', '#button_add', function(e) {
        //Si une taile est bien sélectionnée
        var select = document.getElementById('prod_taille');
        var value = select.options[select.selectedIndex].value;
        if (value != '') {
            $(this).boom(e);
        }
    });

});

if ($('.product_page #add_basket_wrapper #button_add').length) {
    //remove loader to avoid javascript fail on animation
    $('.product_page #add_basket_wrapper #loader_add').remove();
    var htmlToAdd = $('<div class="btn-particles"></div>');
    $('.product_page #add_basket_wrapper .loader_container').append(htmlToAdd);
}

if ($('.product_page .size_filter .input_select.size').attr('disabled')) {
    $('.product_page .size_filter .input_container').addClass('uniqueSize');
}

if ($('.newsletter form label[for="subscribe_ecrm"]').length) {
    setTimeout(function() {
        $('#menu_account_slider_mob .swiper-slide:last-of-type').addClass('activeMenu')
    }, 1000);
}
// FP - Set Associated Products Swiper slides_per_view param into a variable to be correctly passed into _app's loadProduct() {} function and be re-init on colorChange (example)
var assoSwiperParams = {
    slidesPerView: 2,
    spaceBetween: 4,
    loop: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
};

function sendMessage(id, message_send, message_fail) {
    var errbox = document.getElementById('item_error');
    var form = $('#form_reply')[0];
    var  fd = new FormData(form);
    var objElement = document.getElementById("loader_1");
    var item_error = document.getElementById('item_error');
    if ($('#msg_txt_' + id).val() == '') {
        $('.modbox_title', errbox).html(Translator.translate('saisi_reponse_svp'));
        objElement.style.display = "none";
        item_error.style.display = "block";
        openModalBox.call(errbox);
        return false;
    }
    if ($('#msg_txt_' + id).val() != '') {
        fd.append('message', $('#msg_txt_' + id).val());
        $.ajax({
            type : 'post',
            url : path_relative_root + create_link("message_detail"),
            data: fd,
            processData: false,  // dire à jQuery de ne pas traiter les données
            contentType: false,   // dire à jQuery de ne pas définir le contentType
            cache: false,
            success : function (response) {
                if (response == '1') {
                    item_error.style.display = "block";
                    $('.modbox_title', errbox).html(message_send);
                    modBoxOpen.call(errbox);
                    window.setTimeout(function () {
                        window.location.reload();
                    }, 3000);

                } else {
                    $('.modbox_title', errbox).html(message_fail);
                    modBoxOpen.call(errbox);
                }
            }
        });
    } else {
        $('.modbox_title', errbox).html(message_fail);
        modBoxOpen.call(errbox);
    }
}


function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp)
{
    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize();
        data += '&idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour+'&send_mail=true&choix=no_impression';
    } else {
        data = 'idOrder='+idCmdOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour+'&send_mail=true';
    }

    $.ajax({
        url : path_relative_root + create_link("ajax_create_etiquette"),
        type : 'post',
        data : data,
        success : function (res) {
            if ( res.substr(0,5) == 'false') {
                alert(Translator.translate('js_error_return_label'));
            } else {
                closeMultiShad('popup_numero_retour');
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}


/**@ validate modal retour */
function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp) {

    var numRetour = "";
    if (typeof idOrderTransp != "undefined")
        numRetour = ajax_file(
            parentPath +
            create_link("ajax_num_etiquette") +
            "?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp +
            "&idOrderTransp=" +
            idOrderTransp
        );
    else
        numRetour = ajax_file(
            parentPath +
            create_link("ajax_num_etiquette") +
            "?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp
        );


    if (numRetour != "false") {

        var transporteur = '';
        // Dans le cas du multi transporteur on définie les bloc qui doive s'afficher
        if (multiTransp == 1) {
            transporteur = '_' + global_transporteur_id;
        }
        var commentaireRetourObjet = "";
        var divCreateNumRetourObjet = document.getElementById("createNumRetour" + transporteur);
        var divPrintPdfObjet = document.getElementById("printPdf" + transporteur);

        if (document.getElementById("commentaireRetourDiv" + transporteur))
            commentaireRetourObjet = document.getElementById(
                "commentaireRetourDiv"
            ).value;

        if (multiTransp !== 1) {
            if (document.getElementById("commentaireRetour") !== null) {
                commentaireRetourObjet = document.getElementById(
                    "commentaireRetour"
                ).value;
            }
        }

        var recommand = $(".recommandation").length;

        var has_errors = false;
        $(".return_quantity").each(function () {
            var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
            return_choice.removeClass('error');

            if ($(this).val() > 0 && return_choice.val() == '0') {
                has_errors = true;
                return_choice.addClass('error');
            }
        });

        if (!has_errors) {
            if (recommand) {
                $(".recommandation p span").css("color", "#000");
                $('p[id^="liste_motif_retour"] .select').css("border", "none");
                $("#divBtnPrintPdf").show();
            }

            data = $('#return_products_form').serialize();
            data += '&idOrder=' + idOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;

            $.ajax({
                url: parentPath + create_link("ajax_create_etiquette"),
                type: 'post',
                data: data,
                success: function (res) {
                    location.reload();
                }
            });
        } else {
            if (recommand) {
                $(".recommandation p span").css("color", "#a40000");
                $('p[id^="liste_motif_retour"] .select').css(
                    "border",
                    "2px solid #a40000"
                );
            }
        }

    }
}

function sendMailAlertStock(type_alert, form, itm_rr_id) {
    var suffix = form.elements['suff'].value ? '_' + form.elements['suff'].value : '',
        mail_alerte_stock = form.elements['mail_alerte_stock'],
        email = mail_alerte_stock.value,
        produit_id = form.elements['produit_id'].value,
        couleur_id = form.elements['couleurProd'].value,
        taille_id = form.elements['itm_size'].value;
    $verif_mail = checkForm('mail', mail_alerte_stock, 'login_email');
    if (email == '' || !$verif_mail) {
        alert(Translator.translate('js_error_mail'));
    } else if (!(couleur_id > 0)) {
        alert(Translator.translate('js_error_couleur'));
    } else if (!(taille_id > 0)) {
        alert(Translator.translate('js_error_size'));
    } else {

        var isOperatedByGlobalE = false;
        if (typeof GEM_Services != "undefined"  && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings != "undefined") {
            isOperatedByGlobalE = GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE;
        } else if (typeof GEP != 'undefined' && GEP.isOperatedByGlobale !== undefined) {
            isOperatedByGlobalE = GEP.isOperatedByGlobale;
        }

        if (isOperatedByGlobalE === true) {
            type_alert = "stock_gbe";
        }

        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: email,
                produit_id: produit_id,
                taille_id: taille_id,
                couleur_id: couleur_id,
                type_alert: type_alert
            },
            success: function(response) {
                $('#bloc_add_alert' + suffix + ' .alert_stock').hide();
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_erreur').hide();
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_form').hide();
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_confirmation').show();
                // if successful let's send data to GTM
                var dataLayer = window.dataLayer || [];
                dataLayer.push({
                    "event": "stockAlert",
                });
            },
            fail: function() {
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_confirmation').hide();
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_erreur').show();
                $('#bloc_add_alert' + suffix + ' .bloc_add_alert_form').show();
            }
        });
    }
}

$(document).ready(function() {
    var lang = $('html').attr('lang');
    if (lang === 'en') {
        function updateLabelToSize() {
            $(".cart_item_size label:first-child").each(function() {
                if ($(this).text().trim() === "Sizes") {
                    $(this).text("Size");
                }
            });
        }

        updateLabelToSize();

        var observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    updateLabelToSize();
                }
            }
        });

         observer.observe(document.body, { childList: true, subtree: true });
    }
});